import React, { Component, createRef } from 'react'
import { Preloader, Placeholder } from 'react-preloading-screen'

import InteractiveAnimation from '../InteractiveAnimation'
import BottomArrow from '../BottomArrow'

import Header from '../../Header'
import Navigator from '../Navigator'
import Footer from '../../Footer'

import PrimaryScreen from '../PrimaryScreen'
import SecondaryScreen from '../SecondaryScreen'
import CapabilityScreen from '../CapabilityScreen'
import ExamplesScreen from '../ExamplesScreen'
import ScreenshotsScreen from '../ScreenshotsScreen'
import StatsScreen from '../StatsScreen'
import TestimonialsScreen from '../TestimonialsScreen'
import SEO from '../../SEO';
import * as styles from './styles.module.css'

import { ResizeObserver } from 'resize-observer'
import chroma from 'chroma-js'
import $ from 'jquery'

import { gsap, ScrollTrigger, registerScrollTrigger } from '../../../utils/gsap'

registerScrollTrigger()

class LandingPage extends Component {

    constructor(props) {
        super(props);

        this._isInteractiveActive = true;

        this._refs = {
            container: createRef(),
            deepSpaceTrigger: createRef(),
            deepSpace: createRef(),
            navigatorContainer: createRef(),
            screenSizer: createRef(),
            interactiveContainer: createRef(),
            primaryScreen: createRef(),
            primaryScreenSpacer: createRef(),
        }

        this.state = {
            exitProgress: 0.0,
        }
    }

    componentDidMount() {

        this._setupExitParallax();

        this._setupDeepSpace();

        this._handleResize();

        this._resizeObserver = new ResizeObserver(() => {
            this._handleResize();
        });
        this._resizeObserver.observe(this._refs.screenSizer.current);

    }

    componentWillUnmount()
    {
        this._resizeObserver.disconnect();
        this._resizeObserver = null;

        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
            trigger.kill();
        });
    }

    _setupExitParallax()
    {
        ScrollTrigger.create({
            trigger: this._refs.container.current,
            start: 'top 0%',
            end: 'top -50%',
            invalidateOnRefresh: true,
            onUpdate: ({ progress }) => {
                this.setState({
                    exitProgress: progress,
                })
            },
        });
    }

    _setupDeepSpace()
    {
        gsap.timeline({
            scrollTrigger: {
                trigger: this._refs.deepSpaceTrigger.current,
                scrub: 1,
                invalidateOnRefresh: true,
                start: 'top 0%',
                end: 'top -2000%',
                pin: true,
                pinSpacing: false,
            },
        })
        .to(this._refs.deepSpace.current, {
            'background-position-y': '-300px'
        })
        ;
    }

    _handleResize() {
        this._calculatePrimaryScreenSpacer();
    }

    _getHeaderHeight() {
        return 80;
    }

    _calculatePrimaryScreenSpacer() {
        const screenRect = this._refs.interactiveContainer.current.getBoundingClientRect();
        const mountainsHeight = 250;

        if (!this._refs.primaryScreen.current) {
            return;
        }
        const primaryRect = this._refs.primaryScreen.current.getBoundingClientRect();

        const availableHeight = screenRect.height - mountainsHeight;
        this._spacerHeight = (availableHeight - primaryRect.height) / 2 + 40;
        this._spacerHeight = Math.max(this._spacerHeight, this._getHeaderHeight());

        $(this._refs.primaryScreenSpacer.current).css('height', `${this._spacerHeight}px`);
    }

    _calculateHeaderProgress() {
        const headerProgressShift = 0.0;
        let headerProgress = Math.max(this.state.exitProgress - headerProgressShift, 0) / (1.0 - headerProgressShift);
        if (headerProgress < 1.0) {
            if (this._refs.primaryScreen.current) {
                const primaryRect = this._refs.primaryScreen.current.getBoundingClientRect();
                let spacerHeight = this._spacerHeight || 200;
                spacerHeight = spacerHeight - this._getHeaderHeight();
                let distance = primaryRect.top - this._getHeaderHeight();

                let progress = ((spacerHeight - distance) / spacerHeight);
                progress = Math.min(1, progress);
                progress = Math.max(0, progress);

                headerProgress = Math.max(progress, headerProgress);
            }
        }
        return headerProgress;
    }

    _setInteractiveActive(value) {
        if (this._isInteractiveActive === value) {
            return;
        }
        this._isInteractiveActive = value;

        if (this._isInteractiveActive) {
            $(this._refs.interactiveContainer.current).css('display', 'block');
            $(this._refs.navigatorContainer.current).css('display', 'none');
        } else {
            $(this._refs.interactiveContainer.current).css('display', 'none');
            $(this._refs.navigatorContainer.current).css('display', 'block');
        }
    }

    render() {

        this._setInteractiveActive(this.state.exitProgress < 1.0);

        const headerProgress = this._calculateHeaderProgress();

        let accentColor = chroma.scale(['#FFFFFF', '#672257'])(headerProgress);
        let showHeaderBox = (headerProgress >= 0.8);

        accentColor = '#FFFFFF';
        // let headerBoxColor = '#080018';


        return <>
            <SEO />

            <Preloader fadeDuration={1000}
                style={{ transition: '0.3s ease-out 0.5s' }}
            >
                <Placeholder>
                    <div className={styles.loadingPlaceholder}>
                        <svg
                            width="120"
                            height="120"
                            viewBox="0 0 54 54"
                            fill="black"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 30.445c.444-.859 1.246-1.133 2.356-1.131 13.194.017 26.388.01 39.582.009 1.03 0 1.761.4 1.991 1.088.214.642-.075 1.348-.784 1.652-.37.16-.836.234-1.26.236-4.321.018-8.643.014-12.964.014-8.89 0-17.78-.01-26.67.007-1.112.002-1.87-.31-2.251-1.18v-.695zm3.007 14.653V34.575h37.398v10.501H26.779c-.04-.872-.012-1.75-.135-2.605-.376-2.632-2.678-4.399-5.419-4.22-2.43.16-4.449 2.329-4.518 4.868-.018.64-.003 1.28-.003 1.979H3.007zm49.791-32.581c-.966 1.033-1.894 2.104-2.91 3.086-.636.615-1.554.488-2.234-.186-1.524-1.511-3.04-3.032-4.559-4.549-1.62-1.619-3.24-3.239-4.863-4.857-1.105-1.102-1.111-1.894-.02-2.984.471-.471.93-.958 1.42-1.41.392-.36.823-.679 1.236-1.016h.867c.432.344.899.651 1.288 1.037 2.935 2.912 5.859 5.835 8.773 8.767.374.376.67.828 1.002 1.246v.866zM36.861 25.422l-1.097 1.075-.804-1.333c-1.721-2.854-3.847-5.096-6.5-6.857l-1.332-.884 1.12-1.167 1.617-1.698a17.317 17.317 0 00-7.831-1.857C12.776 12.677 6.052 19.747 4.4 27h35.198a17.34 17.34 0 00-1.088-3.258 130.92 130.92 0 01-1.649 1.679z"
                                fill="black"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M45.465 16.389c-.421.416-.84.89-1.32 1.29-.607.504-1.252.387-1.87-.067-.792.733-5.267 5.148-6.083 5.905-1.589-2.494-3.614-4.538-6.127-6.116.78-.769 5.24-5.216 5.991-5.957-.69-1.064-.633-1.542.227-2.404.313-.313.62-.631.906-.923l8.276 8.271zM24.043 45.098c.01-.707.02-1.385-.016-2.059-.072-1.322-.977-2.358-2.052-2.444-1.136-.09-2.213.709-2.366 1.98-.1.822-.064 1.668-.041 2.523h4.475z"
                                fill="black"
                            />
                        </svg>
                        <span>Loading...</span>
                    </div>
                </Placeholder>
                <div ref={this._refs.container} className={styles.container}>

                    <div ref={this._refs.deepSpaceTrigger} className={styles.deepSpaceTrigger}>
                        <div ref={this._refs.deepSpace} className={styles.deepSpace}>

                        </div>
                    </div>

                    <div ref={this._refs.screenSizer} className={styles.screenSizer}>
                    </div>

                    <div ref={this._refs.interactiveContainer} className={styles.interactiveContainer}>
                        <InteractiveAnimation exitProgress={this.state.exitProgress} />
                    </div>

                    <div className={styles.headerContainer}>
                        <Header
                            forceFullLogo={false}
                            isLandingPage={true}
                            logoColor={accentColor}
                            showBox={showHeaderBox} />
                    </div>

                    <div ref={this._refs.navigatorContainer} className={styles.navigatorContainer}>
                        <Navigator />
                    </div>

                    <div className={styles.innerContent}>

                        <div className={styles.primaryScreenWrapper}>
                            <div ref={this._refs.primaryScreenSpacer}
                                className={styles.primaryScreenSpacer} />
                            <PrimaryScreen inputRef={this._refs.primaryScreen} />
                        </div>

                        <div id="safety-spacer" className={styles.spacer} />

                        <SecondaryScreen />

                        <div className={styles.spacer} />

                        <CapabilityScreen />

                        <div className={styles.spacer} />

                        <StatsScreen />

                        <div className={styles.spacer} />

                        <ExamplesScreen />

                        <div className={styles.spacer} />

                        <ScreenshotsScreen />

                        <div className={styles.spacer} />

                        <TestimonialsScreen />

                        <div className={styles.spacer} />

                        <div className={styles.smallSpacer} />

                        <Footer darkBackground />

                    </div>

                    <BottomArrow />

                </div>
            </Preloader>
        </>
    }

}


export default LandingPage
