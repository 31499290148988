// extracted by mini-css-extract-plugin
export var button = "styles-module--button--pXD3p";
export var buttonGroup = "styles-module--buttonGroup--3zR+C";
export var container = "styles-module--container--LX9ZW";
export var description = "styles-module--description--hi5z-";
export var mainPoints = "styles-module--mainPoints--DGNw0";
export var pointContainer = "styles-module--pointContainer--ihT9h";
export var pointNumber = "styles-module--pointNumber--SAxDd";
export var pointText = "styles-module--pointText--6qwsD";
export var subtitle = "styles-module--subtitle--OmOTp";
export var subtitleWrapper = "styles-module--subtitleWrapper--JogNZ";
export var title = "styles-module--title--Rp8Qz";
export var titleWrapper = "styles-module--titleWrapper--tg7z-";
export var wrapper = "styles-module--wrapper--BaK-A";