import { gsap } from '../../../utils/gsap'

class CometAnimator {

    constructor(comet)
    {
        this._elem = comet;
        this._cometLines = Array.from(this._elem.querySelectorAll(".asteroid-tail"));
        this._cometBodyLines = Array.from(this._elem.querySelectorAll(".asteroid-body-tail"));
    }

    run()
    {
        gsap.to(this._cometLines[0], {
            duration: 0.3,
            attr: { "stroke-dashoffset" : '-30' },
            repeat: -1,
            ease: "none"
        });
        gsap.to(this._cometLines[1], {
            duration: 0.25,
            attr: { "stroke-dashoffset" : '-15' },
            repeat: -1,
            ease: "none"
        });
        gsap.to([this._cometLines[2], this._cometLines[3]], {
            duration: 0.4,
            attr: { "stroke-dashoffset" : '-20' },
            repeat: -1,
            ease: "none"
        });
        gsap.to(this._cometBodyLines[0], {
            duration: 0.3,
            attr: { "x1" : "35.2", "y1" : "15.4" },
            repeat: -1,
            yoyo: "true",
            ease: "power4.in"
        });
        gsap.to(this._cometBodyLines[1], {
            duration: 0.3,
            attr: { "x2" : "33.4", "y2" : "21.7" },
            repeat: -1,
            yoyo: "true",
            ease: "power1.in"
        })
    }

    stop()
    {
        gsap.killTweensOf(this._cometBodyLines);
        gsap.killTweensOf(this._cometLines);
    }
}

export default CometAnimator
