import React from 'react';
import * as styles from './styles.module.css'
import { LANDING_PAGE } from '../../../../content/data/landingPage';
import * as commonStyles from '../commonStyles.module.css'

const ExamplesScreen = () => {

    const pageData = LANDING_PAGE.examples

    return <>

        <section id="examples" className={styles.container}>

            <div className={`${styles.title} ${commonStyles.title}`}>{pageData.title}</div>
            
            <div className={styles.wrapper}>
                {pageData.cases.map(item => (
                    <div className={styles.itemContainer} key={item.id}>
                        <div className={styles.itemImage}
                             style={{ backgroundImage: `url('${item.imageUrl}`}}>
                        </div>
                        <div className={styles.itemContent}>
                            <div className={`${styles.itemTitle} ${commonStyles.title}`}>{item.title}</div>
                            <div className={`${styles.itemDescription} ${commonStyles.text}`}>{item.content}</div>
                        </div>
                    </div>
                ))}
            </div>

        </section>
    </>
}

export default ExamplesScreen
