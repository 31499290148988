export const SECTION_NAMES = [
    'top', // x
    // "safety",
    'capabilities', // x
    'stats', // x
    // "examples",
    'screenshots', // x
    // "testimonials",
];

export const LANDING_PAGE = {
    primary: {
        title: 'Friendly Experiences with your Kube',
        subTitle:
            'Make your Kubernetes environment easy to understand and safe to use',
        messages: [
            {
                title: 'Avoid mistakes and costly outages',
            },
            {
                title: 'Gain unique application insights',
            },
            {
                title: 'Reduce problem resolution time',
            },
        ],
        cta: [
            // {
            //     title: 'Try now for Free!',
            //     link: 'https://portal.kubevious.io',
            // },
            {
                title: 'Demo',
                link: 'https://demo.kubevious.io',
            },
            {
                title: 'Open-Source',
                link: '/open-source',
            },
        ],
    },
    secondary: {
        title: 'SAFETY',
        messages: [
            {
                title: 'Clarity',
                description:
                    'See correlated application configurations all under your fingertips.',
                imageUrl: '/img/safety/clarity.svg',
            },
            {
                title: 'Awareness',
                description:
                    'Identify issues, configuration conflicts and imminent breaking before pushing them to production.',
                imageUrl: '/img/safety/awareness.svg',
            },
            {
                title: 'Assurance',
                description:
                    'Achieve ultimate guarantee that apps are running in compliances with company policies and cloud native best practices.',
                imageUrl: '/img/safety/assurance.svg',
            },
        ],
    },
    screenshots: [
        {
            imageName: '01-visualization',
            content:
                'Clearly identify Kubernetes cluster and application correlated configurations in an engineer friendly UI.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/application-centric-ui/',
                target: '_blank'
            },
        },
        {
            imageName: '02-properties',
            content:
                'Access Kubernetes YAML configuration and relevant properties at any level.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/application-centric-ui/',
                target: '_blank'
            },
        },
        {
            imageName: '03-alerts',
            content:
                'Kubernetes errors, warnings and best practice violations.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/application-centric-ui/',
                target: '_blank'
            },
        },
        {
            imageName: '04-time-machine',
            content: 'Travel back in time and identify why things failed.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/time-machine/',
                target: '_blank'
            },
        },
        {
            imageName: '05-rule-editor',
            content:
                'Define your own rules to validate configuration and identify objects of interest.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/rules-engine/',
                target: '_blank'
            },
        },
        {
            imageName: '06-marker-editor',
            content:
                'Associate smart icons with any object that matches your rules.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/rules-engine/',
                target: '_blank'
            },
        },

        {
            imageName: '07-slack',
            content:
                'Receive critical updates and run troubleshooting from the Slack workspace.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/slack-bot/',
                target: '_blank'
            },
        },
        {
            imageName: '08-search',
            content:
                'Find Kubernetes resources using a Google-like search.',
            cta: {
                title: 'Learn More',
                url: '/docs/features/search-engine/',
                target: '_blank'
            },
        },
        {
            imageName: '09-rbac',
            content:
                'Simplify Network Policies, RBAC and other k8s troubleshooting',
            cta: {
                title: 'Learn More',
                url: '/docs/features/cloud-native-tools/',
                target: '_blank'
            },
        },
    ],
    capabilities: [
        {
            id: 1,
            title: 'Safe Kube',
            content: 'Kubevious helps you avoid breaking apps and producing bad or conflicting configurations. It brings operational safety to your apps and makes your teams efficient and successful, without interfering with existing DevOps processes.',
            details:
                'Finally, you got the Kubernetes cluster up and running. Kudos! Now, you need to run apps on top of Kubernetes. How would you do that safely? How would you be sure that you don’t produce bad or conflicting configuration? With Kubernetes it is extremely easy to do so. Remember, the only reason you got into Kubernetes is to run apps!\n' +
                '\n' +
                'Kubernetes is a powerful but complex system. It is extremely easy to produce bad or conflicting configurations leading to costly errors and downtime. Kubevious helps you avoid those challenges. It brings operational safety to your apps and makes your teams efficient and successful, without interfering with existing DevOps processes.',
            imageUrl: '/img/capabilities/safe-kube.svg',
        },
        {
            id: 2,
            title: 'Obvious Kube',
            content: 'Kubevious helps Kubernetes operators to quickly identify configuration specifics, inconsistencies, compliance, and best practices violations.',
            details:
                'Are you tired of chasing gazillions of Kubernetes YAML files and matching labels? Kubevious correlates all configuration files into a simple application-centric view.\n' +
                '\n' +
                'The tools provided by Kubevious help Kubernetes operators to quickly identify configuration specifics, identify inconsistencies, compliance, and best practices violations.',
            imageUrl: '/img/capabilities/obvious-kube.svg',
        },
        {
            id: 3,
            title: 'App Kube',
            content: 'Kubevious application-centric UI is unique. By correlating configurations, it allows operators to be efficient and get the most out of Kubernetes.',
            details:
                'Kubevious application-centric UI is unique. It allows operators to be efficient and get the most out of Kubernetes.\n' +
                '\n' +
                'Kubernetes has a UX problem. The way APIs are designed is perfect for robots to consume, but it is almost unusable for a human beings. The majority of outages are attributed to those reasons resulting in bad or conflicting configurations.\n' +
                '\n' +
                'By solving UX issues, Kubevious makes everybody happy - engineers, executives, and customers.',
            imageUrl: '/img/capabilities/app-kube.svg',
        },
        {
            id: 4,
            title: 'Best Kube',
            content: 'Kubevious validates and enforces cloud-native best practices. Achieve ultimate safety across all domains: application configuration, state, RBAC, storage, networking, service mesh, and more.',
            details:
                'Kubevious validates and enforces cloud-native best practices. Achieve ultimate safety across all domains: application configuration, state, RBAC, storage, networking, service mesh, and more. An open library of rules provides ultimate safety for apps that run on top of Kubernetes.\n' +
                '\n' +
                'Every organization is unique and has specific requirements. With Kubevious you get to define your own rules to enforce best practices and compliance matching your needs. ',
            imageUrl: '/img/capabilities/best-kube.svg',
        },
        {
            id: 5,
            title: 'Friendly Kube',
            content: 'Kubernetes operators love the user friendly and intuitive interface provided by Kubevious.',
            details:
                'Kubevious makes Kubernetes super easy to use. SREs, Developers, and Kubernetes Operators love the user friendly and intuitive interface provided by Kubevious.\n' +
                '\n' +
                'You see and validate your application manifests, config maps, storage, and networking configs all in one place. We didn’t stop there. You would also see CRD and other very complex configs such as service mesh, Calico, Cilium, and others. See the full list of integrations here.',
            imageUrl: '/img/capabilities/friendly-kube.svg',
        },
    ],
    examples: {
        title: 'Application Centric + Controlled',
        cases: [
            {
                id: 1,
                title: 'Identifying Blast Radius',
                content:
                    'Have you ever experienced unintended changes and consequences? Did you ever fix one service in a way that brakes another? Kubevious helps you clearly identify the affected radius to eliminate unintended changes.',
                imageUrl: '/img/examples/blast-radius.svg',
            },
            {
                id: 2,
                title: 'Running Forensics',
                content:
                    'Node goes down, CI/CD pipeline fails mid way, changes across different pipelines cause configuration and state conflicts. How to identify and recover the cluster a minute, hour or a day later? Kubevious lets you travel back and time to see the state of your cluster and application configuration before the mess happened.',
                imageUrl: '/img/examples/forensics.svg',
            },
            {
                id: 3,
                title: 'Enforcing Custom Rules',
                content:
                    'Every organization has unique compliance requirements. There is no rule that fits everyone and every app. Kubevious is equipped with rules engine which was purposely build to enforce application and cloud native best practices in Kubernetes.',
                imageUrl: '/img/examples/enforcement.svg',
            },
        ],
    },
    stats: [
        {
            id: 1,
            value: 100,
            positive: true,
            message: 'Application config & state clarity',
        },
        {
            id: 2,
            value: 100,
            positive: true,
            message: 'Compliance violations detected',
        },
        {
            id: 3,
            value: 90,
            positive: false,
            message: 'Configuration conflicts',
        },
        {
            id: 4,
            value: 80,
            positive: false,
            message: 'Problem resolution time',
        },
    ],
    testimonials: {
        title: 'What People Say About Kubevious',
        items: [
            {
                id: 1,
                name: 'Roman Tarnavski',
                avatar: 'roman',
                role: 'Head of Tanzu Presales, VMWare',
                message:
                    'In an environment where microservice deathstars are the norm - Kubevious is a nice departure to reality.',
                link: 'https://twitter.com/romant/status/1246982747886735361',
            },
            {
                id: 2,
                name: 'Tilt.dev',
                avatar: 'tilt',
                role: 'A multi-service dev environment for teams on Kubernetes',
                message:
                    'We <3 centering application in Kubernetes. Check out Kubevious!',
                link: 'https://twitter.com/tilt_dev/status/1230526784833282048',
            },
            {
                id: 3,
                name: 'William Lam',
                avatar: 'william',
                role: 'Senior Staff Architect, VMware',
                message:
                    'What is really cool about Kubevious is how you can navigate through your application in a hierarchical fashion and quickly drill down into the application details and configurations within a single view.',
                link:
                    'https://www.virtuallyghetto.com/2020/04/useful-interactive-terminal-and-graphical-ui-tools-for-kubernetes.html',
            },
            {
                id: 4,
                name: 'Alfredo Garcia',
                avatar: 'alfredo',
                role: 'API Architect, Orange Spain',
                message:
                    "Really impressed by Kubevious - Kubernetes cluster management visualization. Flawless UI, tons of contextualized info and a very straightforward installation process.",
                link: 'https://twitter.com/agarcia/status/1272534580050882562',
            },
        ]
    },
}
