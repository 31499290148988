import React, { Component, createRef } from 'react'
import LogoAnimator from './logo-animator'
import * as styles from './styles.module.css'
import * as _ from 'lodash';

class Telescope extends Component{

    constructor(props)
    {
        super(props);

        this._refs = {
            logo: createRef(),
            telescopeMask: createRef(),
            tube: createRef(),
            tubeClip: createRef(),
            tubeWrapper: createRef(),
            tubeTop: createRef()
        }

    }

    componentDidMount() {
        var currentRefs = {};
        for(var x of _.keys(this._refs))
        {
            currentRefs[x] = this._refs[x].current;
        }

        this._logoAnimator = new LogoAnimator(currentRefs, true);

        this.applyProps();
    }

    runSelfTest()
    {
        setTimeout(() => {
            this._logoAnimator.openTelescope();
        }, 1000);

        setTimeout(() => {
            this._logoAnimator.rotateToCoordinate(1000, -1000)
        }, 3000);

        setTimeout(() => {
            this._logoAnimator.closeTelescope();
        }, 6000);

        setTimeout(() => {
            this._logoAnimator.openTelescope();
        }, 10000);

        setTimeout(() => {
            this._logoAnimator.rotateToCoordinate(-1000, -1000)
        }, 10200);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.applyProps(nextProps);
        return false
    }

    applyProps(props)
    {
        if (!props) {
            props = this.props;
        }

        this._exitProgress = props.exitProgress || 0;
        this._isInteractive = this._exitProgress > 0.01;

        if (props.isOpen) {
            this._logoAnimator.openTelescope();
        } else {
            this._logoAnimator.closeTelescope();
        }

        if (props.target) {
            if (props.target.kind == 'position')
            {
                this._logoAnimator.rotateToCoordinate(props.target.position.x, props.target.position.y)
            }
            else if (props.target.kind == 'element')
            {
                this._logoAnimator.rotateToElement(props.target.element)
            }
        }

        if (props.color) {
            this._refs.logo.current.style.fill = props.color;
        } else {
            delete this._refs.logo.current.style.fill;
        }
    }

    render() {
        return (
            <svg className={styles.logo} ref={this._refs.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40">

                <defs>
                    <clipPath id="telescope-tube-clip">
                        <path ref={this._refs.tubeClip}
                            d="M14.8,28c0.9-4.6,5-8,9.8-8c4.8,0,8.9,3.4,9.8,8l-0.1,12L50,40V0H0v40l14.8,0"/>
                    </clipPath>
                    <mask id="telescope-mask">
                        <rect fill="#fff" x="0" y="0" width="100%" height="100%"/>
                        <rect ref={this._refs.telescopeMask} fill="#000" x="20.4" y="8.5" width="8.3"
                            height="18.4"/>
                        <path fill="#fff" d="M24.6,20.8c-4.3,0-7.9,3.1-8.8,7.2h17.5C32.6,23.9,29,20.8,24.6,20.8z"/>
                    </mask>
                </defs>

                <rect fill="#35373E" x="12" y="30" width="25" height="29"/>
                <polyline fill="#35373E" points="12,30 13,27.7 36.3,28 37,30"/>

                <path
                    d="M12,40v-7h24.9v7h-9.1c0-0.6,0-1.2-0.1-1.7c-0.3-1.8-1.8-2.9-3.6-2.8c-1.6,0.1-3,1.5-3,3.2c0,0.4,0,0.9,0,1.3H12z"/>
                <path
                    d="M26,40c0-0.5,0-0.9,0-1.4c0-0.9-0.6-1.6-1.4-1.6c-0.8-0.1-1.5,0.5-1.6,1.3c-0.1,0.5,0,1.1,0,1.7H26z"/>
                <path
                    d="M38.3,31.5H11c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h27.2c0.5,0,1,0.4,1,1v0C39.3,31,38.8,31.5,38.3,31.5z"/>

                <g ref={this._refs.tube} clipPath="url(#telescope-tube-clip)">
                    <g ref={this._refs.tubeWrapper}>
                        <g ref={this._refs.tubeTop}>
                            <ellipse fill="#4A90E2" cx="24.5" cy="6.6" rx="5" ry="0.9"/>
                            <rect x="18.8" y="6.5" rx="1" ry="1" width="11.5" height="4.4"/>
                        </g>
                        <path className="tube-body"
                            d="M20.7,12.6v1.1c0,0.6,0.5,1,1,1v7.6h5.8v-7.6c0.6,0,1-0.5,1-1.1v-1H20.7z"/>
                    </g>
                </g>

                <path mask="url(#telescope-mask)"
                    d="M24.6,18.4c-5.8,0-10.6,4.1-11.7,9.6h23.4C35.2,22.5,30.4,18.4,24.6,18.4z"/>
                <circle className="telescope-rotation-center" cx="24.5" cy="28" r="1" fill="transparent"/>
            </svg>
        )
    }
}

export default Telescope
