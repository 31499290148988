import { gsap } from '../../../utils/gsap'



class ExitAnimator {

    constructor(scope, refs)
    {
        this._scope = scope;
        this._refs = refs;
        this._layerCount = this._refs.backgroundLayers.length

        this._progress = 0;
        this._tweens = [];
    }

    stop()
    {
        this._killTweens();
    }

    _killTweens()
    {
        for(var x of this._tweens)
        {
            x.kill();
        }
        this._tweens = []; 
    }

    setProgress(progress)
    {
        let progressValue = Math.floor(progress * 100) / 100

        if (this._progress === progressValue) {
            return;
        }

        this._progress = progressValue

        // this._killTweens();

        for(let i = 0; i < this._layerCount; i++)
        {
            let maxDeltaY = this._progress * (0.3 + ((this._layerCount - (i)) / this._layerCount));
            this._transition(this._refs.backgroundLayers[i].container, {
                y: `${maxDeltaY * 100}vh`
            });
        }

        this._transition(this._refs.sky, {
            opacity: 1 - this._progress
        });

        this._transition(this._refs.celestialBodies, {
            y: `-${this._progress * 100}vh`
        });
    }

    _transition(target, options)
    {
        let tween = gsap.to(target, options);
        // this._tweens.push(tween);
    }
}

export default ExitAnimator
