import $ from 'jquery'
import CometAnimator from './comet-animator'
import { gsap, MotionPathPlugin, registerMotionPathPlugin } from '../../../utils/gsap'

registerMotionPathPlugin()

class SceneAnimator {

    constructor(scope, refs, telescopeControl)
    {
        this._scope = scope;
        this._refs = refs;
        this._telescopeControl = telescopeControl;

        this._backgroundDayLayersRefs = this._refs.backgroundLayers.map(x => x.day);

        this._skyTopColor = this._refs.skyGradientStops[0];
        this._skyBottomColor = this._refs.skyGradientStops[1];

        this._skyDayColor = '#4331E4';
        this._skyDayColor2 = '#ffb8b8'; //'#DBF3FF';
        this._skyNightColor = '#160E5D';
        this._skyNightColor2 = '#C86DD7';
        this._obsDayColor = '#FFFFFF';
        this._obsNightColor = '#CCCCCC';

        this._isDay = true;
        this._cometAlt = false;

        this._moonDuration = 0.4;
        this._sunDuration = 0.4;

        this.cometStart = 0.20;
        this.cometDuration = 0.15;

        this._isInteractive = true;

        this._starTimelines = {};

        // $(this._refs.telescope).css('display', 'block');

        this._cometAnimator = new CometAnimator(refs.comet);

        // _masterTimelineSpeed is speed coefficient.
        //
        // We assuming the whole loop duration as 1
        // * 0 is start of the day
        // * 0.5 is start of the night
        //
        // All the values in the _masterTimeline are relative to 1, not exact seconds.
        // So you can speed up / slow down the whole thing by playing with this coefficient
        this._masterTimelineSpeed = 0.03;

        this._isStopped = false;

        this.recalculateSizes();
        this._setupClouds();
        this._setupLoop();
    }

    setInteractive(value)
    {
        if (this._isInteractive === value) {
            return;
        }
        this._isInteractive = value;

        this._activateTimeline();
    }

    run()
    {
        this._activateTimeline();
    }

    stop()
    {
        this._isStopped = true; 

        if (this._masterTimeline)
        {
            this._masterTimeline.kill();
            this._masterTimeline = null;
        }

        this._cometAnimator.stop();
    }

    _activateTimeline()
    {
        if (!this._isInteractive) {
            this._masterTimeline.pause();
        } else {
            this._masterTimeline.play();
        }
    }

    _setupStars()
    {
        if (!this._isDay) {
            this._refs.stars.forEach((star, index) => {
                this._processStar(star, index);
            });
        }
    }

    _processStar(star, index)
    {
        if (this._starTimelines[index]) {
            return;
        }

        const minSize = 10;
        const largeSize = 30;
        const maxSize = 40;
        const largeStarChance = 0.2;
        const size = 
            (Math.random() <= largeStarChance) ?
            (largeSize + Math.random() * (maxSize - largeSize)) :
            minSize + Math.random() * (largeSize - minSize);

        const initalDelay = Math.random() * 9;
        const visibleDuration = Math.random() * 3;
        const fadeDuration = 1;
        const exitStartTime = initalDelay + visibleDuration + fadeDuration;

        const pos = {
            x: Math.random() * this._screenSize.width,
            y: Math.random() * this._screenSize.height * 0.3,
        };
        
        let tl = gsap.timeline({
            paused: true
        })
        .call(() => {
            $(star).css('display', 'none');
        }, [], 0)
        .set(star, {
            x: pos.x, //this._screenSize.width - 100,
            y: pos.y,
            opacity: 0
        }, initalDelay)
        .call(() => {
            $(star).css('display', 'block');
            $(star).css('width', `${size}px`);
            $(star).css('height', `${size}px`);
        }, [], initalDelay)
        .to(star, {
            opacity: 1,
            duration: fadeDuration
        }, initalDelay + 0.1)

        .to(star, {
            opacity: 0,
            duration: fadeDuration
        }, exitStartTime)
        .call(() => {
            $(star).css('display', 'none');
            delete this._starTimelines[index];
            if (!this._isDay) {
                this._processStar(star);
            }
        }, [], exitStartTime + fadeDuration + 0.1)

        this._starTimelines[index] = tl;

        tl.play();

    }

    _setupLoop()
    {
        var self = this;

        this._masterTimeline = gsap.timeline({
                paused: true,
                repeat: -1, // makes timeline looped
            })
            .timeScale(this._masterTimelineSpeed)

        this._setupNight(0, this.cometStart, this.cometDuration);
        this._setupDay(0.5);

        this._setupPositions();

        this._masterTimeline
            .eventCallback("onRepeat", () => {
                self._setupComet(self.cometStart, self.cometDuration);
            })
            .eventCallback("onUpdate", () => {
                // TODO
                if (!self._isDay) // TODO: && this._scope.logoRenderer._extendTimeline.progress() > 0.01)
                {
                    if (self._isCometVisible) {
                        this._targetTelescope(self._refs.comet);
                        // this._scope.logoRenderer.rotateToElement(self._comet);
                    } else {
                        this._targetTelescope(self._refs.moon);
                        // this._scope.logoRenderer.rotateToElement(this._refs.moon);
                    }
                }
            })
    }

    _setupPositions()
    {
        this._setupMoon(0);
        this._setupSun(0.5);
        this._setupComet(this.cometStart, this.cometDuration);
    }

    _setupDay(startPos)
    {
        var self = this;

        this._masterTimeline
            // ---------------------------------------------------------
            // DAY starts: all the transitions start in 0 point
            //
            .call(function () {
                self._startDay();
            }, null, startPos)
            // sky color transition
            .fromTo(this._skyTopColor, {
                attr: { "stop-color" : this._skyNightColor }
            }, {
                attr: { "stop-color" : this._skyDayColor },
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            .fromTo(this._skyBottomColor, {
                attr: { "stop-color" : this._skyNightColor2 }
            }, {
                attr: { "stop-color" : this._skyDayColor2 },
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            // logo color transition
            .fromTo(this._refs.telescope, {
                fill: this._obsNightColor
            }, {
                fill: this._obsDayColor,
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            // mountain transition
            .to(this._backgroundDayLayersRefs, {
                duration: 0.25,
                opacity: 1
            }, startPos)
            // stars fade out
            // .to(this._stars, {
            //     duration: 0.05,
            //     opacity: 0
            // }, startPos)
            // telescope close
            .call(() => {
                this._openTelescope(false);
            }, null, startPos)
        }

        _setupNight(startPos, cometStartPos, cometDuration)
        {
            var self = this;

            this._masterTimeline
            // ---------------------------------------------------------
            // NIGHT starts: all the transitions start in 0.5 point
            //
            .call(function () {
                self._startNight();
            }, null, startPos)
            // sky color transition
            .fromTo(this._skyTopColor, {
                attr: { "stop-color" : this._skyDayColor }
            }, {
                attr: { "stop-color" : this._skyNightColor },
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            .fromTo(this._skyBottomColor, {
                attr: { "stop-color" : this._skyDayColor2 }
            }, {
                attr: { "stop-color" : this._skyNightColor2 },
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            // logo color transition
            .fromTo(this._refs.telescope, {
                fill: this._obsDayColor
            }, {
                fill: this._obsNightColor,
                duration: 0.25,
                ease: "power1.inOut"
            }, startPos)
            // mountain transition
            .to(this._backgroundDayLayersRefs, {
                duration: 0.25,
                opacity: 0
            }, startPos)
            .call(function () {
                self._isCometVisible = true;
            }, null, cometStartPos + cometDuration * 0.01)
            .call(function () {
                self._isCometVisible = false;
            }, null, cometStartPos + cometDuration)

            // set start opacity to 1 after night->day transition
            // .set(this._stars, {
            //     opacity: 1,
            //     scale: 0,
            //     transformOrigin: "50% 50%"
            // }, startPos)
            // stars scale up
            // .to(this._stars, {
            //     duration: 0.05,
            //     scale: 1,
            //     stagger: 0.02,
            //     esse: "back(3)"
            // }, startPos)
            // telescope open
            .call(() => {
                this._openTelescope(true);
            }, null, startPos)
            ;
    }

    _startDay()
    {
        this._isDay = true;
        this._cometAlt = !this._cometAlt;
        this._cometAnimator.stop();
    }

    _startNight()
    {
        this._isDay = false;
        this._setupStars();
        this._cometAnimator.run();
    }

    _setupSun(startPos)
    {
        gsap.killTweensOf(this._refs.sun);

        // sun moves
        this._masterTimeline
            .to(this._refs.sun, {
                duration: this._sunDuration,
                motionPath: {
                    path: [
                        {x: 0, y: 0},
                        {x: -this._screenSize.width * 0.3 - this._refs.sun.clientWidth * 0.5, y: -this._screenSize.height * 0.5},
                        {x: -this._screenSize.width * 0.7 - this._refs.sun.clientWidth * 0.5, y: -this._screenSize.height * 0.5},
                        {x: -this._screenSize.width - this._refs.sun.clientWidth, y: 0}
                    ],
                    type: "cubic"
                },
                rotation: 360,
                ease: "none"
            }, startPos + 0.05)
    }

    _setupMoon(startPos)
    {
        gsap.killTweensOf(this._refs.moon);

        // moon moves
        this._masterTimeline
            .to(this._refs.moon, {
                duration: this._moonDuration,
                motionPath: {
                    path: [
                        {x: 0, y: 0},
                        {x: -this._screenSize.width * 0.3 - this._refs.moon.clientWidth * 0.5, y: -this._screenSize.height * 0.5},
                        {x: -this._screenSize.width * 0.7 - this._refs.moon.clientWidth * 0.5, y: -this._screenSize.height * 0.5},
                        {x: -this._screenSize.width - this._refs.moon.clientWidth, y: 0}
                    ],
                    type: "cubic"
                },
                ease: "none"
            }, startPos + 0.05)
    }

    _setupComet(cometStartPos, cometDuration)
    {
        gsap.killTweensOf(this._refs.comet);

        const comentTrajectories = [{
            from: { x: this._screenSize.width * 0.2, y: 0 },
            to: [
                {x: this._screenSize.width * 0.2, y: 0},
                {x: -this._screenSize.width * 0.9, y: this._screenSize.height * 0.53},
                {x: -this._screenSize.width - this._refs.comet.clientWidth, y: this._screenSize.height * 0.6}
            ]
        }, {
            from: { x: -this._screenSize.width - this._refs.comet.clientWidth, y: 0 },
            to: [
                {x: -this._screenSize.width - this._refs.comet.clientWidth, y: 0},
                {x: this._screenSize.width * 0.3, y: this._screenSize.height * 0.6},
                {x: this._screenSize.width * 0.4, y: this._screenSize.height * 0.3}
            ]
            }]
        
        var selectedTrajectory = this._cometAlt ? comentTrajectories[0] : comentTrajectories[1];

        this._masterTimeline
            .set(this._refs.comet, {
                x: this._cometAlt ? window.innerWidth * 0.2 : -window.innerWidth - this._refs.comet.clientWidth,
                y: 0
            }, 0)
            .to(this._refs.comet, {
                duration: cometDuration,
                motionPath: {
                    path: selectedTrajectory.to,
                    alignOrigin: [0.5, 0.5],
                    autoRotate: -150
                },
                ease: "none",
            }, cometStartPos)
    }

    _setupClouds()
    {
        this._refs.clouds.forEach((cloud) => {
            gsap.to(cloud, {
                delay: Math.random() * 20,
                duration: Math.random() * 5 + 15,
                x: "140vw",
                repeat: -1,
                ease: "none"
            })
            .progress(Math.random())
        });
    }

    focusStar(target)
    {
        // TODO
        // this._scope.logoRenderer.rotateToElement(target);
    }
    
    _openTelescope(value)
    {
        this._telescopeControl.setIsOpen(value);
    }

    _targetTelescope(elem)
    {
        this._telescopeControl.setTarget({
            kind: 'element',
            element: elem
        });
    }

    recalculateSizes()
    {
        if (this._isStopped) {
            return;
        }

        this._recalculateWindowSize();
        this._recalculateTelescopeSize();
    }

    _recalculateWindowSize()
    {
        this._screenSize = this._refs.container.getBoundingClientRect();
    }

    _recalculateTelescopeSize()
    {
        var bgOrigSize = { width: 1920, height: 1080 };
        var logoOrigSize = { width: 200, height: 0 };

        var size = this._refs.telescopeBackgroundLayerContainer.getBoundingClientRect();
        var targetPos = { x: 590, y: 270 };

        var scale = size.width / bgOrigSize.width;

        var projectedPos = {
            x: targetPos.x * scale,
            y: targetPos.y * scale
        }

        var logoScale = Math.max(0.5, scale);
        var targetLogoWidth = logoOrigSize.width * logoScale;
        var targetLogoHeight = targetLogoWidth * 200 / 300;

        $(this._refs.telescope).css('width', targetLogoWidth + 'px');
        $(this._refs.telescope).css('height', targetLogoHeight + 'px');

        $(this._refs.telescope).css('left', projectedPos.x - targetLogoWidth / 2);
        $(this._refs.telescope).css('bottom', projectedPos.y);
    }

    responsiveTimeline()
    {
        if (this._isStopped) {
            return;
        }
        this._setupPositions();
    }
}

export default SceneAnimator
