// extracted by mini-css-extract-plugin
export var authorAvatar = "styles-module--authorAvatar--frTm8";
export var authorInfo = "styles-module--authorInfo--92yR6";
export var authorName = "styles-module--authorName--SfT90";
export var authorRole = "styles-module--authorRole--lM6KP";
export var block = "styles-module--block--t9yBF";
export var container = "styles-module--container--ZT4w4";
export var header = "styles-module--header--6N4gK";
export var itemContent = "styles-module--itemContent--SEW-O";
export var title = "styles-module--title--CdhKE";
export var wrapper = "styles-module--wrapper--PGlqG";