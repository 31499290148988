import React, { useState } from 'react'
import { LANDING_PAGE } from '../../../../content/data/landingPage'

import * as styles from './styles.module.css'

const ScreenshotsScreen = () => {
    const [hoveredScreenIndex, setHoveredScreenIndex] = useState(null)

    const pageData = LANDING_PAGE.screenshots

    var screenRefs = {}
    for(let i = 0; i < pageData.length; i++) {
        screenRefs[i] = {
            img: React.createRef(),
        }
    }

    const handleMouseEnter = (screenData, index) => {
        const elem = screenRefs[index].img.current;
        elem.style.transform = 'scale(1.1)';

        setHoveredScreenIndex(index);
    }

    const handleMouseLeave = (screenData, index) => {
        const elem = screenRefs[index].img.current;
        elem.style.transform = 'scale(1)';

        setHoveredScreenIndex(null);
    }

    return (
        <section id="screenshots" className={styles.container}>
            <div className={styles.gridWrapper}>
                {pageData.map((screen, index) => (
                    <div
                        key={index}
                        className={styles.screenContainer}
                        role="button"
                        tabIndex='0'
                        onMouseEnter={() => handleMouseEnter(screen, index)}
                        onMouseLeave={() => handleMouseLeave(screen, index)}
                    >
                        <a href={screen.cta.url} target={screen.cta.target}>
                            <img
                                ref={screenRefs[index].img}
                                className={styles.screen}
                                src={`/img/screenshots/${screen.imageName}.jpg`}
                                alt="Kubevious Screenshot"
                            />
                        </a>

                        {(hoveredScreenIndex !== index) && (
                            <div className={styles.screenInfo}>
                                <div className={styles.screenInfoInner}>
                                    <div className={styles.screenContent}>
                                        {screen.content}
                                    </div>

                                    <a
                                        className={styles.screenButton}
                                        href={screen.cta.url}
                                        target={screen.cta.target}
                                    >
                                        {screen.cta.title}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    )
}

export default ScreenshotsScreen
