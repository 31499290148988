import React from 'react'
import * as styles from './styles.module.css'
import { LANDING_PAGE } from '../../../../content/data/landingPage'

import * as commonStyles from '../../../styles/common-components.module.css'

const PrimaryScreen = (props) => {
    const pageData = LANDING_PAGE.primary;

    return (
        <section id="top" className={styles.container} ref={props.inputRef}>

            <div className={styles.wrapper}>

                <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                        {pageData.title}
                    </div>
                </div>

                <div className={styles.subtitleWrapper}>
                    <div className={styles.subtitle}>
                            {pageData.subTitle}
                            </div>
                </div>

                <div className={styles.mainPoints}>
                    {pageData.messages.map((item, index) => (
                        <div key={index} className={styles.pointContainer}>
                            <div className={styles.pointNumber}>
                                {(index + 1)}
                            </div>
                            <div className={styles.pointText}>
                                {
                                item.title.split('\n').map((x, index) => {
                                    return <span key={index}>{x}</span>
                                })
                                }</div>
                        </div>
                    ))}
                </div>

                <div className={styles.buttonGroup}>
                    {pageData.cta.map((item, index) => (
                        <a href={item.link}
                        className={`${commonStyles.linkButtonLight} ${styles.button}`}
                        key={index}>
                        {item.title}
                        </a>
                    ))}
                </div>

            </div>

        </section>
    )
};

export default PrimaryScreen
