// extracted by mini-css-extract-plugin
export var container = "styles-module--container--ZRxF-";
export var contents = "styles-module--contents--88iO4";
export var deepSpace = "styles-module--deepSpace--T0D1t";
export var deepSpaceTrigger = "styles-module--deepSpaceTrigger--H4KXl";
export var emptyDiv = "styles-module--emptyDiv--Kh6nP";
export var headerContainer = "styles-module--headerContainer--36Dc-";
export var innerContent = "styles-module--innerContent--1tka5";
export var interactiveContainer = "styles-module--interactiveContainer--fT-dd";
export var loadingPlaceholder = "styles-module--loading-placeholder--Q1p79";
export var navigatorContainer = "styles-module--navigatorContainer--C3zSl";
export var primaryScreenSpacer = "styles-module--primaryScreenSpacer--YO+VS";
export var primaryScreenWrapper = "styles-module--primaryScreenWrapper--X69Jf";
export var screenSizer = "styles-module--screenSizer--0+JRC";
export var smallSpacer = "styles-module--smallSpacer--FjW3z";
export var spacer = "styles-module--spacer--Gmf4n";