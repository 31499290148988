import jQuery from 'jquery'
import { gsap } from '../../utils/gsap'

class LogoAnimator {

    constructor(logoRefs)
    {
        this._rotationDegreeMax = 60;
        this._currentProgress = null;

        this._isOpen = false;

        this._elem = logoRefs.logo;
        this._telescopeMask = logoRefs.telescopeMask;
        this._tube = logoRefs.tube;
        this._tubeClip = logoRefs.tubeClip;
        this._tubeWrapper = logoRefs.tubeWrapper;
        this._tubeTop = logoRefs.tubeTop;

        this._rotationDuration = 5;

        this._setupTimelines();
    }

    _setupTimelines()
    {
        this._isExtending = false;

        this._rotateTimeline = gsap.timeline({
                defaults: { duration: this._rotationDuration, "ease": "none" },
                paused: true
            })
            .fromTo(this._tubeClip, {
                svgOrigin: "24.5 28",
                rotation: this._rotationDegreeMax,
            }, {
                rotation: -this._rotationDegreeMax
            })
            .fromTo([this._tube, this._telescopeMask], {
                svgOrigin: "24.5 28",
                rotation: -this._rotationDegreeMax,
            }, {
                rotation: this._rotationDegreeMax
            }, 0);

        this._rotateTimeline.progress(1);

        this._extendTimeline = gsap.timeline({ paused: true })
            .call(() => {
                // console.log('[LogoAnimator] Extend Begin');
                this._isExtending = true;
            })
            .fromTo(this._telescopeMask, {
                svgOrigin: "24.5 28",
                scaleX: 0
            }, {
                scaleX: 1.5,
                duration: 0.9
            })
            .from(this._tubeWrapper, {
                duration: 2,
                y: 16
            }, 0.4)
            .from(this._tubeTop, {
                duration: 1.8,
                y: 5
            }, 0.9)
            .to(this._telescopeMask, {
                scaleX: 1,
                duration: 0.7
            }, 1.6)
            .call(() => {
                // console.log('[LogoAnimator] Extend End');
                this._isExtending = false;
            });

    }

    _getElementPosition(target)
    {
        if (target instanceof jQuery) {
            target = target.get()[0];
        }
        const rect = target.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        var pos = {
            left: rect.left + scrollLeft,
            top: rect.top,
            width: rect.width,
            height: rect.height
        }
        pos.x = pos.left + pos.width /2;
        pos.y = pos.top + pos.height /2;
        return pos;
    }

    _getPosition()
    {
        return this._getElementPosition(this._elem);
    }

    _setRotation(progress)
    {
        if (this._currentProgress == progress) {
            return;
        }
        if (this._isExtending) {
            return;
        }
        if (!this._isOpen) {
            return;
        }

        // console.log('[LogoAnimator] _setRotation')

        this._currentProgress = progress;

        gsap.to(this._rotateTimeline, {
            duration: 1,
            progress: progress
        });

        // TODO: For some reason play
        // this._rotateTimeline.pause(progress * this._rotationDuration);
    }

    openTelescope()
    {
        if (this._isOpen) {
            return;
        }
        this._isOpen = true;

        // console.log('[LogoAnimator] openTelescope')
        this._extendTimeline.play(0);
    }

    closeTelescope()
    {
        if (!this._isOpen) {
            return;
        }
        this._isOpen = false;

        // console.log('[LogoAnimator] closeTelescope')

        this._extendTimeline.reverse();
    }

    rotateToMouse(e)
    {
        // this.rotateToCoordinate(e.pageX, e.pageY);
    }

    rotateToElement(target)
    {
        // console.log('[LogoAnimator] rotateToElement: ', target)

        var pos = this._getElementPosition(target);
        this.rotateToCoordinate(pos.x, pos.y);
    }

    rotateToCoordinate(x, y)
    {
        // console.log('[LogoAnimator] rotateToCoordinate: ', x, y)

        // return;
        if (this._isExtending)
        {
            return;
        }

        const logoPosition = this._getPosition();

        var dy = logoPosition.y - y;
        var dx = logoPosition.x - x ;
        var theta = Math.atan2(dy, dx);
        theta *= 180 / Math.PI;
        if (theta < 0) {
            if (theta < -90) {
                theta = 180;
            } else {
                theta = 0;
            }
        }

        var alpha = theta - (90 - this._rotationDegreeMax);
        alpha = Math.max(alpha, 0);
        alpha = Math.min(alpha, 2 * this._rotationDegreeMax );

        var progress = alpha / (2 * this._rotationDegreeMax);
        progress = Math.max(progress, 0);
        progress = Math.min(progress, 1);

        this._setRotation(progress)
    }

}

export default LogoAnimator
