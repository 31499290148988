import React from 'react';
import * as styles from './styles.module.css'
import { LANDING_PAGE } from '../../../../content/data/landingPage';
import * as commonStyles from '../commonStyles.module.css'

const StatsScreen = () => {
    const pageData = LANDING_PAGE.stats;

    return (
        <section id="stats" className={styles.container}>

            <div className={styles.wrapper}>
                {pageData.map(item => (
                    <div className={styles.block} key={item.id}>
                        <div className={styles.numberContainer}>
                            {!item.positive && (
                                <img className={styles.arrowDown}
                                     src="/img/landing-page/stats-arrow-down.svg"
                                     alt="arrow"
                                />
                            )}
                            <div className={`${styles.number} ${item.positive ? styles.positive : styles.negative}`}>
                                {item.value}%
                            </div>
                        </div>
                        
                        <div className={`${styles.description} ${commonStyles.text}`}>
                            {item.message}
                        </div>
                    </div>
                ))}
            </div>

        </section>
    )
}

export default StatsScreen
