import React, { useEffect, useRef } from 'react'
import * as styles from './styles.module.css'
import { gsap } from '../../../utils/gsap'

const BottomArrow = () => {
    const container = useRef(null)

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: "#mainContent",
                scrub: true,
                start: "0% 0%",
                end: "+=100px"
            }
        })
            .fromTo(container.current, {
                opacity: 1,
            }, {
                opacity: 0
            });
    }, []);

    return (
        <div className={styles.container} ref={container}>
            <a href="#safety-spacer" className={`${styles.arrow} ${styles.bounce}`}></a>

            {/* <img className={styles.image} src="/img/design/scrolldown.png" alt="down" /> */}
        </div>
    )
};

export default BottomArrow
