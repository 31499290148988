import React, { useEffect, useRef, useState, useCallback } from 'react'
import * as styles from './styles.module.css'
import $ from 'jquery'
import * as _ from 'lodash'
import { ResizeObserver } from 'resize-observer'
import { SECTION_NAMES } from '../../../../content/data/landingPage';

const Navigator = () => {
    const containerRef = useRef(null)

    const [selectedIndex, setSelectedIndex] = useState(0);

    const SCROLL_OFFSET = 90;
    const SCROLL_BUFFER = 10;

    const SECTIONS = _.map(SECTION_NAMES, x => ({
        name: x,
        position: 0,
    }));

    const calculateSelected = useCallback(() => {
        const scrollPos = $('html').scrollTop();
        
        for (let i = SECTIONS.length - 1; i >= 0; i--) {
            let x = SECTIONS[i];
            if (scrollPos >= (x.position - SCROLL_OFFSET - SCROLL_BUFFER)) {
                setSelectedIndex(i);
                return;
            }
        }
        
        setSelectedIndex(0);
    }, [setSelectedIndex, SECTIONS])
    
    const calculatePositions = useCallback(() => {
        for (let x of SECTIONS) {
            x.position = $(`#${x.name}`).offset().top;
        }

        calculateSelected();
    }, [calculateSelected, SECTIONS])

    const _handleResize = useCallback(() => {
        calculatePositions()
    }, [calculatePositions])

    useEffect(() => {

        let mounted = true;

        let resizeObserver = new ResizeObserver(() => {
            if (!mounted) {
                return;
            }
            _handleResize();
        });
        resizeObserver.observe(containerRef.current);

        let handleScrollFunction = (event) => {
            if (!mounted) {
                return;
            }
            calculateSelected();
        }

        document.addEventListener('scroll', handleScrollFunction, true /*Capture event*/);

        calculatePositions();

        return function cleanup() {
            mounted = false;
            resizeObserver.disconnect();
            document.removeEventListener('mousemove', handleScrollFunction, true);
        }
    }, [_handleResize, calculateSelected, calculatePositions]);

    function scrollTo(item, e) {
        const hash = `#${item.name}`;
        let pos = 0;
        if (item.name !== 'top') {
            pos = $(hash).offset().top - SCROLL_OFFSET;
        }
        pos = Math.max(0, pos);
        $('html, body').animate({
            scrollTop: pos,
        }, 800, function () {
        });
    }

    return (
        <div className={styles.container} ref={containerRef}>
            {SECTIONS.map((item, index) => (
                <div key={index} className={styles.stepWrapper}
                     onClick={() => {
                         scrollTo(item);
                     }}>
                    <div
                        className={`${styles.stepOuter} ${
                            (index === selectedIndex)
                                ? styles.selectedStep
                                : ''
                        }`}
                    />
                    <div className={styles.stepInner} />
                </div>
            ))}
        </div>
    )
};

export default Navigator
