import React from 'react'
import { LANDING_PAGE } from '../../../../content/data/landingPage'
import * as styles from './styles.module.css'
import * as commonStyles from '../commonStyles.module.css'

const CapabilityScreen = () => {
    const pageData = LANDING_PAGE.capabilities;

    return <>

        <section id="capabilities" className={styles.container}>

            {pageData.map((block) => (
                <div className={styles.blockContainer}
                     key={block.id}>
                    <div className={styles.imageWrapper}
                         style={{ backgroundImage: `url('${block.imageUrl}`}} >
                    </div>
                    <div className={styles.screenContainer}>
                        <div className={`${styles.screenTitle} ${commonStyles.title}`}
                            >{block.title}</div>
                        <div className={`${styles.screenContent} ${commonStyles.text}`}
                            id={`screenContent${block.id}`}>{block.content}</div>
                    </div>
                </div>
            ))}

        </section>


    </>
};

export default CapabilityScreen
