export const gsap = typeof window !== 'undefined' ? window.gsap : {}

export const ScrollTrigger =
    typeof window !== 'undefined'
        ? window.ScrollTrigger
        : {}

export const MotionPathPlugin =
    typeof window !== 'undefined'
        ? window.MotionPathPlugin
        : {}

export const registerScrollTrigger = () => {
    if (gsap.registerPlugin) {
        gsap.registerPlugin(ScrollTrigger)
    }
}
export const registerMotionPathPlugin = () => {
    if (gsap.registerPlugin) {
        gsap.registerPlugin(MotionPathPlugin)
    }
}
