import React from 'react'
import { LANDING_PAGE } from '../../../../content/data/landingPage'
import * as styles from './styles.module.css'
import * as commonStyles from '../commonStyles.module.css'

const SecondaryScreen = () => {
    const pageData = LANDING_PAGE.secondary;

    return <>
        <section id="safety" className={styles.container}>

            <div className={`${styles.title} ${commonStyles.title}`}>
                {pageData.title}
            </div>

            <div className={`${styles.wrapper}`}>
                {pageData.messages.map((point, index) => (
                    <div className={styles.point}
                            key={index}>
                        <img
                            src={point.imageUrl}
                            alt={point.title}
                            className={styles.pointImage}
                        />
                        <div className={`${styles.pointTitle} ${commonStyles.title}`}>
                            {point.title}
                        </div>
                        <div className={`${styles.pointDescription} ${commonStyles.text}`}>
                            {point.description}
                        </div>
                    </div>
                ))}
            </div>

        </section>
    </>
};

export default SecondaryScreen
