// extracted by mini-css-extract-plugin
export var backgroundLayer = "styles-module--backgroundLayer--+VPuN";
export var backgroundLayerGroup = "styles-module--backgroundLayerGroup--gO-Hw";
export var backgrounds = "styles-module--backgrounds--7kKhH";
export var celestialBodies = "styles-module--celestialBodies--0yLyI";
export var cloud = "styles-module--cloud--y0zZu";
export var cloudContainer = "styles-module--cloudContainer--Fu2-F";
export var comet = "styles-module--comet--ZI3XV";
export var container = "styles-module--container--cSq7W";
export var content = "styles-module--content--A9TWT";
export var moon = "styles-module--moon--NceLY";
export var sky = "styles-module--sky--w1Qg-";
export var star = "styles-module--star--1nmSS";
export var stars = "styles-module--stars---N79s";
export var sun = "styles-module--sun--A0CR5";
export var telescope = "styles-module--telescope--9yMem";