import React, { Component, createRef } from 'react'
import SceneAnimator from './scene-animator'
import ExitAnimator from './exit-animator'
import { ResizeObserver } from 'resize-observer'
import Telescope from '../../Telescope'
import * as styles from './styles.module.css'
import * as _ from 'lodash'
import $ from 'jquery'

const KUBERNETES_STAR_COUNT = 7;
const CLOUD_COUNT = 9;
class InteractiveAnimation extends Component {

    constructor(props)
    {
        super(props);

        this._isActive = true;

        this.state = {
            isTelescopeOpen: false,
            telescopeTarget: null,
        }

        this._refs = {
            container: createRef(),
            
            sky: createRef(),
            skyGradientStops: [],

            celestialBodies: createRef(),

            clouds: [],
            
            backgrounds: createRef(),
            backgroundLayers: [],

            telescope: createRef(),
            telescopeControl: createRef(),

            moon: createRef(),
            sun: createRef(),
            comet: createRef(),

            stars: []
        }

        for(let i = 0; i < 2; i++)
        {
            this._refs.skyGradientStops.push(createRef());
        }

        for(let i = 0; i < CLOUD_COUNT; i++)
        {
            this._refs.clouds.push(createRef());
        }

        this._landscapeLayers = [
            'mount-right-3',
            'mount-right-2',
            'mount-right-1',
            'mount-left',
            'fg-2',
            'fg-1',
        ]
        this._telescopeLayer = 'fg-2';

        for(var x of this._landscapeLayers)
        {
            this._refs.backgroundLayers.push({
                name: x,
                includesTelescope: (x === this._telescopeLayer),
                container: createRef(),
                day: createRef(),
                night: createRef()
            })
        }

        {
            const telescopeBackgroundLayer = _.find(this._refs.backgroundLayers, x => x.includesTelescope);
            if (!telescopeBackgroundLayer) {
                throw new Error("COULD NOT PLACE TELESCOPE");
            }
            this._refs.telescopeBackgroundLayerContainer = telescopeBackgroundLayer.container;
        }

        for(let i = 0; i < KUBERNETES_STAR_COUNT; i++)
        {
            this._refs.stars.push(createRef());
        }
    }

    componentDidMount() {
        this._scope = {};

        var currentRefs = {};
        for(var x of _.keys(this._refs))
        {
            let value = this._refs[x]
            if (_.isArray(value))
            {
                currentRefs[x] = value.map(item => {
                    if (x === 'backgroundLayers')
                    {
                        return {
                            name: item.name,
                            container: item.container.current,
                            day: item.day.current,
                            night: item.night.current
                        }
                    }
                    else
                    {
                        return item.current
                    }
                });
            }
            else
            {
                currentRefs[x] = value.current;
            }
        }

        this._scope.exitAnimator = new ExitAnimator(this._scope, currentRefs);

        this._scope.sceneAnimator = new SceneAnimator(this._scope, currentRefs, {
            setIsOpen: (value) => {
                this.setState({ isTelescopeOpen: value });
            },
            setTarget: (value) => {
                this.setState({ telescopeTarget: value });
            }
        });

        this.applyProps();

        this._scope.sceneAnimator.run();

        this._scope.observer = new ResizeObserver(() => {
            this._handleResize();
        });
        this._scope.observer.observe(currentRefs.container);
        
    }

    componentWillUnmount()
    {
        if (this._scope.observer) {
            this._scope.observer.disconnect();
            this._scope.observer = null;
        }

        this._scope.sceneAnimator.stop();

        this._scope.exitAnimator.stop();
    }

    _handleResize()
    {
        this._scope.sceneAnimator.recalculateSizes();
        this._scope.sceneAnimator.responsiveTimeline();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.applyProps(nextProps);

        return true;
    }

    applyProps(props)
    {
        // if props are equal all things below will calculate, but we don't want this
        if (props === this.props) {
            return
        }

            if (!props) {
                props = this.props;
            }
            
            this._exitProgress = props.exitProgress || 0;
            this._exitProgress = Math.min(this._exitProgress, 1);
            this._exitProgress = Math.max(this._exitProgress, 0);
            
            this._isInteractive = this._exitProgress < 0.5;
            
            this._scope.sceneAnimator.setInteractive(this._isInteractive);
            this._scope.exitAnimator.setProgress(this._exitProgress);
            
            this._setActive(this._exitProgress < 1.0); 
    }

    _setActive(value)
    {
        if (this._isActive === value) {
            return;
        }
        this._isActive = value;

        if (this._isActive)
        {
            $(this._refs.container.current).css('display', 'block');
        }
        else
        {
            $(this._refs.container.current).css('display', 'none');
        }
    }

    render() {
        return <>
        
            <div ref={this._refs.container} className={styles.container}>

                <div ref={this._refs.sky}
                     className={styles.sky}>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="sky-gradient" 
                                            className={styles.sky}
                                            x1="0%" y1="0%"
                                            x2="0%" y2="100%">
                                <stop ref={this._refs.skyGradientStops[0]}
                                      offset="0" stopColor="#160E5D"/>
                                <stop ref={this._refs.skyGradientStops[1]}
                                      offset="1" stopColor="#C86DD7"/>
                            </linearGradient>
                        </defs>
                        <rect x="0" y="0" width="100%" height="100%" fill="url(#sky-gradient)"/>
                    </svg>
                </div>

                <div ref={this._refs.celestialBodies} className={styles.celestialBodies} >

                    <div className={styles.stars}>
                        { this._refs.stars.map((item, index) => (
                            <div ref={item}
                                 key={index}
                                 className={styles.star}
                                 />
                        ))}
                    </div>

                    <div ref={this._refs.sun} className={styles.sun}/>
                    
                    <div ref={this._refs.moon} className={styles.moon}/>

                    <svg ref={this._refs.comet} className={styles.comet} viewBox="0 0 60 44.3" strokeLinecap="round">
                        <line className="asteroid-tail" strokeDasharray="15 15" strokeDashoffset="0" stroke="#fe7d43"
                            strokeWidth="2" x1="28" y1="33.6" x2="55.9" y2="17.7" fill="none"/>
                        <line className="asteroid-tail" transform="translate(0 2)" stroke="#ffd78c" strokeDasharray="5 10"
                            strokeDashoffset="0" strokeWidth="1.5" x1="18.4" y1="17.6" x2="46.3" y2="1.7"/>
                        <line className="asteroid-tail" stroke="#fe7d43" strokeDasharray="5 15" strokeDashoffset="0"
                            strokeWidth="1.5" x1="18.4" y1="17.6" x2="46.3" y2="1.7"/>
                        <line transform="translate(4 10)" className="asteroid-tail" stroke="#fe7d43" strokeDasharray="5 15"
                            strokeDashoffset="0" strokeWidth="1.5" x1="18.4" y1="17.6" x2="46.3" y2="1.7"/>
                        <line className="asteroid-body-tail" stroke="#ffe8a0" strokeWidth="5" x1="56.2" y1="3.2" x2="24"
                            y2="21.9"/>
                        <line className="asteroid-body-tail" stroke="#ffe8a0" strokeWidth="5.5" x1="26" y1="25.9" x2="53.9"
                            y2="10"/>
                        <g>
                            <path fill="#ffe8a0"
                                d="M6.9,18.9l14.7-8.5a.5.5,0,0,1,.8.6L22,15.4s3.9-1,6,1.3l2,10A4.2,4.2,0,0,1,31,31c1.9-.1,4.8,0,6.7.1a.6.6,0,0,1,.3,1.1L20.3,42.4a13.6,13.6,0,0,1-18.6-5A13.7,13.7,0,0,1,6.9,18.9Z"/>
                            <path fill="#ffd78c"
                                d="M7,18.8h.1a14.4,14.4,0,0,0-2.3,17,14.6,14.6,0,0,0,15.5,6.9A13.8,13.8,0,0,1,1.8,37.4,13.6,13.6,0,0,1,7,18.8Z"/>
                            <path fill="#fe7d43"
                                d="M8.9,23.3l4.4-2.6a2.9,2.9,0,0,1,4.1,1.1h.1a1.5,1.5,0,0,0,2.1.6l.7-.4a2.1,2.1,0,0,1,2.9.7,2.2,2.2,0,0,1-.8,2.9l-3,1.8a1.3,1.3,0,0,0-.6,1.6,7.6,7.6,0,0,1,.4,1.1A1.2,1.2,0,0,0,21,31h.2a2.2,2.2,0,0,1,3.2.9,2.4,2.4,0,0,1-.8,3.3l-5.9,3.4a8.8,8.8,0,0,1-12-3A8.9,8.9,0,0,1,8.9,23.3Z"/>
                            <path fill="#fd5426"
                                d="M6.2,25.5a9.3,9.3,0,0,0,.9,6.9,8.6,8.6,0,0,0,12,3.1l5.6-3.3A2.6,2.6,0,0,1,23.6,35l-5.9,3.5a8.7,8.7,0,0,1-12-3.1A9.3,9.3,0,0,1,6.2,25.5Z"/>
                            <path fill="#fd5426"
                                d="M9.9,39.1a4.5,4.5,0,0,1,1.7-5,3.7,3.7,0,0,1,5.1,1.4,4.1,4.1,0,0,1,.3,3.4A8.8,8.8,0,0,1,9.9,39.1Z"/>
                            <path fill="#d73d24"
                                d="M10.3,35.6a8.3,8.3,0,0,0,6.7.8,3.6,3.6,0,0,1,0,2.5,8.7,8.7,0,0,1-7.1.2A4,4,0,0,1,10.3,35.6Z"/>
                            <path fill="#fd5426"
                                d="M8.8,23l3.7-1.9a4,4,0,0,1-1.7,5.4A3.9,3.9,0,0,1,6,25.8,9.3,9.3,0,0,1,8.8,23Z"/>
                        </g>
                    </svg>

                    <div ref={this._refs.cloudContainer}
                        className={styles.cloudContainer}>
                        { this._refs.clouds.map((item, index) => (
                            <img ref={item}
                                key={index}
                                className={styles.cloud}
                                src={`/img/clouds/cloud-${(index % 3) + 1}.svg`} alt="cloud"/>
                        ))
                        }
                    </div>

                </div>

                <div ref={this._refs.backgrounds}
                     className={styles.backgrounds} >

                    {this._refs.backgroundLayers.map((layer, index) => (
                        <div ref={layer.container}
                             key={index}
                             className={styles.backgroundLayerGroup}>
                            <div ref={layer.night} 
                                 className={styles.backgroundLayer}
                                 style={{ backgroundImage: `url('/img/interactive/landscape/night/${layer.name}.svg'`}}
                                 />
                            <div ref={layer.day}
                                 className={styles.backgroundLayer}
                                 style={{ backgroundImage: `url('/img/interactive/landscape/day/${layer.name}.svg'`}}
                                 />

                            { (layer.includesTelescope) &&  (
                                <div ref={this._refs.telescope} className={styles.telescope}>
                                <Telescope ref={this._refs.telescopeControl}
                                           isOpen={this.state.isTelescopeOpen} 
                                           target={this.state.telescopeTarget} /> 
                            </div>
                            )}

                        </div>
                    ))}

                </div>

            </div>
        </>
    }
}


export default InteractiveAnimation;
