import React from 'react';
import * as styles from './styles.module.css'
import { LANDING_PAGE } from '../../../../content/data/landingPage';
import * as commonStyles from '../commonStyles.module.css'

const TestimonialsScreen = () => {
    const pageData = LANDING_PAGE.testimonials

    const findAvatar = (currentAuthor) => {
        if (currentAuthor) {
            return `/img/testimonials/${currentAuthor}.jpg`
        }
        return '/img/design/avatar.jpg'
    }

    return (
        <section id="testimonials" className={styles.container}>

            <div className={`${styles.title} ${commonStyles.title}`}>{pageData.title}</div>

            <div className={styles.wrapper}>
                {pageData.items.map(item => (
                    <div key={item.id} className={styles.block} id={`item-${item.id}`}>
                        <div className={styles.header}>
                            <img src={findAvatar(item.avatar)} alt={item.name} className={styles.authorAvatar} />

                            <div className={styles.authorInfo}>
                                <div className={styles.authorName}>
                                    {item.name}
                                </div>

                                <div className={styles.authorRole}>
                                    {item.role}
                                </div>
                            </div>
                        </div>

                        <div className={styles.itemContent}>
                            <a href={item.link} target='_blank' rel="noreferrer">
                                &quot;{item.message}&quot;
                            </a>
                        </div>
                    </div>
                ))}
            </div>

        </section>
    )
}

export default TestimonialsScreen
